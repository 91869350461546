<template>
  <!-- dashboard template -->

  <div class="animated fadeIn pa-3 pt-0">
    <loading v-if="vLoading" />
    <v-dialog
      v-if="role == 'reception'"
      v-model="startShiftDialog"
      persistent
      width="700px"
    >
      <!-- NOTE INJA -->
      <v-card class="pa-3" style="border-radius: 20px">
        <v-card-title class="mt-5">
          <h4>آغاز شیفت</h4>
        </v-card-title>
        <v-card-text>
          <v-container class="text-right">
            <h5 class="mb-5">
              لطفا نام کادر درمان و پرسنل شیفت را انتخاب کنید
            </h5>
            <v-row>
              <v-col>
                <v-progress-circular
                  v-if="busy"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-autocomplete
                  v-else
                  v-model="shiftDoc"
                  :items="drNames.filter((x) => x.expertise != 'دندانپزشک')"
                  item-text="text"
                  item-value="value"
                  label="نام پزشک*"
                  class="pa-0"
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-progress-circular
                  v-if="busy"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-autocomplete
                  outlined
                  dense
                  v-else
                  v-model="shiftNurse"
                  :items="nurseNames"
                  item-text="text"
                  item-value="value"
                  label="نام پرستار*"
                  class="pa-0"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <!--<v-row>
              <v-col>
                <v-progress-circular
                  v-if="busy"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-autocomplete
                  outlined
                  dense
                  v-else
                  v-model="dentistId"
                  :items="drNames"
                  item-text="text"
                  item-value="value"
                  label="دندانپزشک"
                  class="pa-0"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-progress-circular
                  v-if="busy"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-autocomplete
                  outlined
                  dense
                  v-else
                  v-model="nurseDentistId"
                  :items="nurseNames"
                  item-text="text"
                  item-value="value"
                  label="دستیار دندانپزشک"
                  class="pa-0"
                ></v-autocomplete>
              </v-col>
            </v-row>-->
            <v-row>
              <v-col>
                <v-progress-circular
                  v-if="busy"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-autocomplete
                  v-else
                  v-model="shiftDocOnCall"
                  :items="drNames.filter((x) => x.value != shiftDoc)"
                  item-text="text"
                  item-value="value"
                  label="نام پزشک آنکال"
                  class="pa-0"
                  outlined
                  dense
                  disabled
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-progress-circular
                  v-if="busy"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-autocomplete
                  v-else
                  v-model="shiftNurseOnCall"
                  :items="nurseNames.filter((x) => x.value != shiftNurse)"
                  item-text="text"
                  item-value="value"
                  label="نام پرستارآنکال"
                  class="pa-0"
                  outlined
                  dense
                  disabled
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row
              ><v-col cols="6">
                <v-select
                  v-model="shift"
                  :items="shifts"
                  item-text="text"
                  item-value="value"
                  label="شیفت*"
                  outlined
                  dense
                ></v-select></v-col
              ><v-col cols="6">
                <v-progress-circular
                  v-if="busy"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-autocomplete
                  outlined
                  dense
                  v-else
                  v-model="servant"
                  :items="servantNames"
                  item-text="text"
                  item-value="value"
                  label="پرسنل خدمات"
                ></v-autocomplete> </v-col
            ></v-row>
            <v-text-field
              label="نام پذیرش: "
              outlined
              v-model="userName"
              disabled
              dense
            ></v-text-field>
            <v-row class="mt-5">
              <i
                class="fa fa-exclamation-triangle fa-2x text-warning mt-1 ms-3 me-3"
                aria-hidden="true"
              ></i>
              <p class="mt-1 text-danger">
                تذکر: تمام مسئولیت قانونی موارد ثبت شده با مسئول پذیرش این شیفت
                خواهد بود
              </p>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="primary-btn pa-5"
            :disabled="!shift || !shiftDoc || !shiftNurse"
            outlined
            @click="startShift()"
          >
            آغاز شیفت
          </v-btn>
        </v-card-actions>
      </v-card>
      <!-- NOTE TA INJA -->
    </v-dialog>
    <v-dialog
      v-if="role == 'reception'"
      v-model="endShiftDialog"
      persistent
      width="800px"
    >
      <v-card style="border-radius: 20px" class="pa-3">
        <v-card-title>
          <h4>فرم پایان شیفت</h4>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row align="center">
              <v-col md="6" cols="12">
                <v-select
                  v-model="report.shift"
                  :items="shifts"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                  label="شیفت"
                ></v-select>
              </v-col>
              <v-col md="6" cols="12">
                <span id="dateFrom3">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="report.date"
                    label=" تاریخ  "
                    :editable="true"
                    class="date-input"
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="report.date"
                  element="dateFrom3"
                  color="#00a7b7"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" cols="12">
                <span id="startshift">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="schedule"
                    v-model="report.shiftStart"
                    label=" از ساعت  "
                    :editable="true"
                    class="date-input"
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="report.shiftStart"
                  element="startshift"
                  color="#00a7b7"
                  type="time"
                />
              </v-col>
              <v-col md="6" cols="12">
                <span id="endshift">
                  <v-text-field
                    outlined
                    dense
                    append-icon="schedule"
                    v-model="report.shiftEnd"
                    label=" لغایت  "
                    :editable="true"
                    class="date-input"
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="report.shiftEnd"
                  element="endshift"
                  color="#00a7b7"
                  type="time"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" cols="12">
                <v-progress-circular
                  v-if="busy"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-autocomplete
                  v-else
                  v-model="report.lastShiftDoc"
                  :items="drNames.filter((x) => x.expertise != 'دندانپزشک')"
                  multiple
                  item-text="text"
                  item-value="text"
                  label="نام پزشک"
                  class="pa-0"
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col md="6" cols="12">
                <v-progress-circular
                  v-if="busy"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-autocomplete
                  v-else
                  v-model="report.lastShiftNurse"
                  :items="nurseNames"
                  multiple
                  item-text="text"
                  item-value="text"
                  label="نام پرستار"
                  outlined
                  dense
                  class="pa-0"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col md="6" cols="12">
                <v-text-field
                  v-model="userName"
                  disabled
                  label="پذیرش"
                  outlined
                  dense
                  class="pa-0"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-progress-circular
                  v-if="busy"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-autocomplete
                  v-else
                  v-model="report.lastShiftServant"
                  :items="servantNames"
                  multiple
                  outlined
                  dense
                  item-text="text"
                  item-value="text"
                  label="پرسنل خدمات"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <hr />
            <h4 class="text-center primary--text">خلاصه کارکرد مالی</h4>
            <br />
            <v-row>
              <v-col md="6" cols="12">
                <vuetify-money
                  v-model="report.pos"
                  :valueWhenIsEmpty="whenIsEmpty"
                  :options="vMoneyOptions"
                  label="کارتخوان(﷼)"
                  type="number"
                  outlined
                  dense
                />
              </v-col>
              <v-col md="6" cols="12">
                <vuetify-money
                  v-model="report.cash"
                  :valueWhenIsEmpty="whenIsEmpty"
                  :options="vMoneyOptions"
                  label="نقدی(﷼)"
                  type="number"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" cols="12">
                <vuetify-money
                  v-model="report.lack"
                  :valueWhenIsEmpty="whenIsEmpty"
                  :options="vMoneyOptions"
                  label="مبلغ کسری صندوق(﷼)"
                  type="number"
                  outlined
                  dense
                />
              </v-col>
              <v-col md="6" cols="12">
                <vuetify-money
                  v-model="report.extra"
                  :valueWhenIsEmpty="whenIsEmpty"
                  :options="vMoneyOptions"
                  label="مبلغ اضافه صندوق(﷼)"
                  type="number"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" cols="12">
                <vuetify-money
                  v-model="report.talab"
                  :valueWhenIsEmpty="whenIsEmpty"
                  :options="vMoneyOptions"
                  label="طلب از بیمار(﷼)"
                  type="number"
                  outlined
                  dense
                />
              </v-col>
              <v-col md="6" cols="12">
                <vuetify-money
                  v-model="report.debt"
                  :valueWhenIsEmpty="whenIsEmpty"
                  :options="vMoneyOptions"
                  label="بدهی به بیمار(﷼)"
                  type="number"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-text-field
              outlined
              label="توضیحات صندوق"
              v-model="report.cashDescription"
              type="text"
            ></v-text-field>
            <hr />
            <div v-if="role == 'reception'">
              <h4 class="text-center primary--text">نسخ الکترونیک ثبت شده</h4>
              <br />
              <v-row>
                <v-col md="4" cols="12">
                  <v-text-field
                    label="بیمه تامین اجتماعی"
                    outlined
                    dense
                    v-model="report.ePrepSocial"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col md="4" cols="12">
                  <v-text-field
                    label="بیمه خدمات سلامت"
                    outlined
                    dense
                    v-model="report.ePrepHealth"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col md="4" cols="12">
                  <v-text-field
                    label="بیمه نیروهای مسلح"
                    outlined
                    dense
                    v-model="report.ePrepMilitary"
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
              <hr />
            </div>
            <div v-if="role == 'reception'">
              <h4 class="text-center primary--text">ارجاعات تخصصی</h4>
              <br />
              <h6 class="text-right">
                آیا در فرآیند اجرای شیفت، ارجاعی به متخصصین انجام گرفته است؟
              </h6>
              <v-radio-group v-model="report.isReferral" row>
                <v-radio label="بله" value="yes"></v-radio>
                <v-radio label="خیر" value="no"></v-radio>
              </v-radio-group>
              <div v-if="report.isReferral == 'yes'">
                <v-row>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="تعداد آزمایشگاه"
                      outlined
                      dense
                      v-model="report.referral.lab.no"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="اسامی بیماران آزمایشگاه"
                      outlined
                      dense
                      v-model="report.referral.lab.names"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="تعداد فیزیوتراپی"
                      outlined
                      dense
                      v-model="report.referral.physio.no"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="اسامی بیماران فیزیوتراپی"
                      outlined
                      dense
                      v-model="report.referral.physio.names"
                      type="text"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="تعداد رادیولوژی"
                      outlined
                      dense
                      v-model="report.referral.radio.no"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="اسامی بیماران رادیولوژی"
                      outlined
                      dense
                      v-model="report.referral.radio.names"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="تعداد دندانپزشکی"
                      outlined
                      dense
                      v-model="report.referral.dentistery.no"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="اسامی بیماران دندانپزشکی"
                      outlined
                      dense
                      v-model="report.referral.dentistery.names"
                      type="text"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="تعداد متخصص زنان"
                      outlined
                      dense
                      v-model="report.referral.women.no"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="اسامی بیماران متخصص زنان"
                      outlined
                      dense
                      v-model="report.referral.women.names"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="تعداد متخصص اطفال"
                      outlined
                      dense
                      v-model="report.referral.babies.no"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="اسامی بیماران متخصص اطفال"
                      outlined
                      dense
                      v-model="report.referral.babies.names"
                      type="text"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="تعداد داخلی"
                      outlined
                      dense
                      v-model="report.referral.internal.no"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="اسامی بیماران داخلی"
                      outlined
                      dense
                      v-model="report.referral.internal.names"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="تعداد قلب و عروق"
                      outlined
                      dense
                      v-model="report.referral.heart.no"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="اسامی بیماران قلب و عروق"
                      outlined
                      dense
                      v-model="report.referral.heart.names"
                      type="text"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="تعداد ارتوپدی"
                      outlined
                      dense
                      v-model="report.referral.ortho.no"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="اسامی بیماران ارتوپدی"
                      outlined
                      dense
                      v-model="report.referral.ortho.names"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="تعداد ENT"
                      outlined
                      dense
                      v-model="report.referral.ent.no"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="اسامی بیماران ENT"
                      outlined
                      dense
                      v-model="report.referral.ent.names"
                      type="text"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="تعداد جراحی"
                      outlined
                      dense
                      v-model="report.referral.surgery.no"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="اسامی بیماران جراحی"
                      outlined
                      dense
                      v-model="report.referral.surgery.names"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="تعداد اورولوژی"
                      outlined
                      dense
                      v-model="report.referral.orology.no"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="اسامی بیماران اورولوژی"
                      outlined
                      dense
                      v-model="report.referral.orology.names"
                      type="text"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="تعداد عفونی"
                      outlined
                      dense
                      v-model="report.referral.infectious.no"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="اسامی بیماران عفونی"
                      outlined
                      dense
                      v-model="report.referral.infectious.names"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="تعداد پوست و مو"
                      outlined
                      dense
                      v-model="report.referral.skin.no"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="اسامی بیماران پوست و مو"
                      outlined
                      dense
                      v-model="report.referral.skin.names"
                      type="text"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </div>
            <hr />
            <h4 class="text-center primary--text">خلاصه اتفاقات شیفت</h4>
            <br />
            <h6 class="text-right">
              1. آیا کمبودی در وسایل و امکانات و تجهیزات در شیفت وجود داشت؟
            </h6>
            <v-radio-group v-model="report.isFacilitiesShortage" row>
              <v-radio label="بله" value="yes"></v-radio>
              <v-radio label="خیر" value="no"></v-radio>
            </v-radio-group>
            <v-text-field
              outlined
              v-if="report.isFacilitiesShortage == 'yes'"
              label="توضیحات و شرح کمبود"
              v-model="report.facilitiesShortage"
              type="text"
            ></v-text-field>
            <h6 class="text-right" style="line-height: 30px">
              2. آیا در طول مدت شیفت بیمار یا مراجعه کننده ای اعتراض /ناراحتی/
              شکایت یا درگیری در خصوص نحوه ارایه خدمات درمانگاه اعم از
              خدمات/پذیرش/ پرستاری/ ازمایشگاه/ دندانپزشکی/ داروخانه و یا پزشک
              درمانگاه داشت؟
            </h6>
            <v-radio-group v-model="report.isDissatisfaction" row>
              <v-radio label="بله" value="yes"></v-radio>
              <v-radio label="خیر" value="no"></v-radio>
            </v-radio-group>
            <v-text-field
              outlined
              v-if="report.isDissatisfaction == 'yes'"
              label="اگر بلی توضیح دهید"
              v-model="report.dissatisfaction"
              type="text"
            ></v-text-field>
            <div class="text-right mb-6">
              <h6 class="mb-2">
                سایر موارد (بیماران بدون نوبت/ توضیحات شکایات):
              </h6>
              <vue-ckeditor v-model="report.others" :config="config" />
            </div>
            <div class="text-right mb-6">
              <h6 class="mb-2">پیشنهاد جهت ارتقاء فرآیند کاری درمانگاه:</h6>
              <vue-ckeditor v-model="report.suggestions" :config="config" />
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            :disabled="
              !(report.pos || report.cash) ||
              !(report.lastShiftDoc && report.lastShiftNurse && report.shift) ||
              !report.ePrepHealth ||
              !report.ePrepSocial ||
              !report.ePrepMilitary
            "
            class="primary-btn pa-5"
            outlined
            @click="endShift()"
          >
            ارسال فرم و پایان شیفت
          </v-btn>
          <v-btn class="red-btn pa-5" outlined @click="endShiftDialog = false">
            لغو
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="role == 'reception'"
      v-model="errorDialog"
      max-width="600px"
    >
      <v-card class="dialog-card">
        <v-card-title>
          <h4 class="primary--text">عدم دسترسی</h4>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col style="line-height: 25px" class="common-text text-justify">
                تنها در صورتی قادر به ارائه گزارش و اتمام شیفت هستید که شیفت را
                آغاز کرده باشید. لطفا با اکانت کارمند پذیرش اصلی این شیفت وارد
                سیستم شوید.
              </v-col>
            </v-row>
            <v-row>
              <v-col class="common-text">
                <span>پذیرش اصلی: </span
                ><span>{{ shiftDetails.receptionName }}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary-btn pa-5" outlined @click="errorDialog = false">
            بستن
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      {{ startShiftDialog }}
    </v-row>
    <v-row v-if="!startShiftDialog">
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-row class="d-btn-row" cols="12" md="4">
          <v-col class="d-btn-col">
            <div class="services-btn d-btn py-6" @click="goToNewRequest">
              <img
                class="div-icon mb-2"
                src="../../assets/images/hospital.svg"
              />

              ثبت خدمات در درمانگاه
            </div>
          </v-col>
          <v-col class="d-btn-col" cols="12" md="4">
            <div class="services-btn d-btn py-6" @click="goToNewHomeCare">
              <img class="div-icon mb-2" src="../../assets/images/folder.png" />
              ثبت خدمات در منزل
            </div>
          </v-col>
          <v-col class="d-btn-col" cols="12" md="4">
            <div class="services-btn d-btn py-6" @click="openAddDialog()">
              <img
                class="div-icon-money mb-2"
                src="../../assets/images/money.png"
              />
              ثبت دریافتی جدید
            </div>
          </v-col>
        </v-row>
        <div>
          <mobile-text
            v-if="deviceType == 'mobile'"
            :title="'آخرین درخواست‌ها'"
          />
          <v-card class="cards pa-3 mt-5" v-else>
            <v-row
              v-if="dashboardInfo.visits"
              class="pa-3 shift-table-card cards"
            >
              <v-card-title class="card-h-title">
                آخرین درخواست‌ها
              </v-card-title>
              <b-table
                show-empty
                :fields="Fields"
                :items="dashboardInfo.visits"
                empty-text="درخواستی برای نمایش وجود ندارد"
                striped
                responsive
              >
                <template v-slot:head()="data">
                  <div style="text-align: center; vertical-align: middle">
                    {{ data.label }}
                  </div>
                </template>
                <template v-slot:cell()="data">
                  <div style="text-align: center; vertical-align: middle">
                    {{ data.value }}
                  </div>
                </template>
                <template v-slot:cell(index)="data">
                  <div style="text-align: center; vertical-align: middle">
                    {{ data.index + 1 }}
                  </div>
                </template>
                <template v-slot:cell(operation)="data">
                  <div style="text-align: center; vertical-align: middle">
                    <v-btn
                      class="primary-btn py-2"
                      :to="'/reception/editrequest/' + data.item.id"
                    >
                      <v-icon> help_outline </v-icon>
                    </v-btn>
                  </div>
                </template>
                <div slot="table-busy" class="text-center primary--text my-2">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    class="align-middle"
                  ></v-progress-circular>
                </div>
              </b-table>
            </v-row>
          </v-card>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
        lg="6"
        :class="deviceType == 'mobile' ? 'pt-3' : ''"
      >
        <v-card class="cards pa-2">
          <v-card-title class="ps-6 pe-6 pt-6">
            <v-row class="card-title-row">
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-row style="align-items: center">
                  <img
                    class="card-icon"
                    src="../../assets/images/medical-result.svg"
                  />
                  <h4 class="ma-3 card-h-title">شیفت در جریان</h4>
                </v-row>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-row style="justify-content: flex-end">
                  <div class="date-div">
                    <span class="span-1"> ساعت </span>
                    <span class="span-2">
                      {{ time }}
                    </span>
                  </div>
                  <div class="date-div ms-3 me-3">
                    <span class="span-1"> روز </span>
                    <span class="span-2">
                      {{ splitDate(currentDate).day }}
                    </span>
                  </div>

                  <div class="date-div">
                    <span class="span-1"> ماه </span>
                    <span class="span-2">
                      {{ splitDate(currentDate).month }}
                    </span>
                  </div>
                </v-row>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text v-if="dashboardInfo.shift" class="ps-6 pe-6 pt-5">
            <v-row class="text-right">
              <v-col class="details-div" md="6" cols="12">
                <span class="h5">پذیرش: </span>
                <span class="h5">{{ dashboardInfo.shift.receptionName }} </span>
              </v-col>
              <v-col class="mr-auto details-div" md="6" cols="12">
                <span class="h5">عنوان شیفت: </span>
                <span class="h5">{{ dashboardInfo.shift.subject }}</span>
              </v-col>
            </v-row>
            <v-row class="details-div">
              <v-col>
                <span class="h5">ساعت آغاز شیفت: </span>
                <span class="h5">{{ dashboardInfo.shift.startTime }} </span>
              </v-col>
            </v-row>
            <hr class="mt-3" />
            <v-row class="mt-2 text-right">
              <v-col class="details-div" md="6" cols="12">
                <span class="h5">پزشک: </span>
                <span class="h5">{{ dashboardInfo.shift.doctorName }} </span>
              </v-col>
              <v-col class="mr-auto details-div" md="6" cols="12">
                <span class="h5">پرستار : </span>
                <span class="h5">{{ dashboardInfo.shift.nurseName }} </span>
              </v-col>
            </v-row>
            <v-row class="text-right">
              <v-col class="details-div" md="6" cols="12">
                <span class="h5">پزشک آنکال: </span>
                <span class="h5">
                  {{ dashboardInfo.shift.oncallDoctorName }}</span
                >
              </v-col>
              <v-col class="mr-auto details-div">
                <span class="h5" md="6" cols="12">پرستار آنکال: </span>
                <span class="h5"
                  >{{ dashboardInfo.shift.oncallNurseName }}
                </span>
              </v-col>
            </v-row>
            <!--<v-row>
              <v-col class="details-div">
                <span class="h5">دندانپزشک: </span>
                <span class="h5"> {{ dashboardInfo.shift.dentistName }}</span>
              </v-col>
              <v-col class="mr-auto details-div">
                <span class="h5">دستیار دندانپزشک : </span>
                <span class="h5">
                  {{ dashboardInfo.shift.nurseDentistName }}</span
                >
              </v-col>
            </v-row>-->
          </v-card-text>
          <v-card-text v-else class="ps-6 pe-6 pt-5">
            <v-row>
              <v-col class="details-div">
                <span class="h5">پذیرش: </span>
                <span class="h5">- </span>
              </v-col>
              <v-col class="mr-auto details-div">
                <span class="h5">عنوان شیفت: </span>
                <span class="h5">-</span>
              </v-col>
            </v-row>
            <v-row class="details-div">
              <v-col>
                <span class="h5">ساعت آغاز شیفت: </span>
                <span class="h5">- </span>
              </v-col>
            </v-row>
            <hr class="mt-3" />
            <v-row class="mt-2">
              <v-col class="details-div">
                <span class="h5">پزشک: </span>
                <span class="h5">- </span>
              </v-col>
              <v-col class="mr-auto details-div">
                <span class="h5">پرستار : </span>
                <span class="h5">- </span>
              </v-col>
            </v-row>
            <v-row class="">
              <v-col class="details-div">
                <span class="h5">پزشک آنکال: </span>
                <span class="h5"> -</span>
              </v-col>
              <v-col class="mr-auto details-div">
                <span class="h5">پرستار آنکال: </span>
                <span class="h5">- </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="details-div">
                <span class="h5">دندانپزشک: </span>
                <span class="h5"> -</span>
              </v-col>
              <v-col class="mr-auto details-div">
                <span class="h5">دستیار دندانپزشک : </span>
                <span class="h5"> -</span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row class="d-btn-row mb-0 mt-0 ps-2 pe-2">
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-btn
                  class="primary-btn d-btn py-6 shift-btn"
                  @click="goToChangeShiftDetails"
                >
                  تغییر اطلاعات شیفت
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-btn
                  class="secondary-btn d-btn py-6 endshift-btn"
                  @click="showEendShift()"
                >
                  اتمام شیفت
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
        <v-row class="mt-2" v-if="dashboardInfo.shift">
          <v-col>
            <v-card class="cards pa-4">
              <v-card-title class="number-card-title">
                <div style="white-space: nowrap; font-size: 16px">
                  شماره نوبت ویزیت
                </div>
                <div class="mt-2 num-div">
                  {{ dashboardInfo.shift.visitNo }}
                </div>
              </v-card-title>
            </v-card>
          </v-col>
          <v-col class="num-div-container">
            <v-card class="cards pa-4">
              <v-card-title class="number-card-title">
                <div style="white-space: nowrap; font-size: 16px">
                  شماره نوبت خدمات
                </div>
                <div class="mt-2 num-div">
                  {{ dashboardInfo.shift.nurseNo }}
                </div>
              </v-card-title>
            </v-card>
          </v-col>
          <v-col class="num-div-container">
            <v-card class="cards pa-4">
              <v-card-title class="number-card-title">
                <div style="white-space: nowrap; font-size: 16px">
                  شماره نوبت تخصص
                </div>
                <div class="mt-2 num-div">
                  {{ dashboardInfo.shift.exVisitNo }}
                </div>
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else>
      <div class="blur-bg"></div>
    </v-row>
    <v-dialog v-model="addReceiveItemDialog" width="700">
      <v-card class="pa-3" style="border-radius: 20px">
        <v-card-title class="mb-2">
          <h4 class="text-right primary--text">ثبت دریافت جدید</h4>
          <v-spacer></v-spacer>
          <v-icon
            color="red"
            class="mb-2 close-icon"
            @click="addReceiveItemDialog = false"
            >mdi-close</v-icon
          >
        </v-card-title>
        <span v-show="false">{{ reactivity }}</span>
        <v-card-text class="pb-0 mb-0">
          <v-radio-group v-model="receiveType" style="margin-right: -15px" row>
            <v-radio label="دریافت از کارمندان" value="employee"></v-radio>
            <v-radio label="دریافتی متفرقه" value="other"></v-radio>
          </v-radio-group>
          <div v-if="receiveType == 'employee'">
            <v-select
              v-model="receiveDetail.userId"
              :items="employees"
              item-text="text"
              item-value="id"
              label="کارمند"
              v-if="!employeeBusy"
              outlined
              dense
              @change="reactivity = !reactivity"
            ></v-select>
          </div>
          <v-textarea
            outlined
            dense
            type="text"
            v-model="receiveDetail.title"
            label="دریافت بابت"
          >
          </v-textarea>

          <vuetify-money
            v-model="receiveDetail.amount"
            label="مبلغ (ریال)"
            :valueWhenIsEmpty="whenIsEmpty"
            :options="vMoneyOptions"
            type="number"
            outlined
            dense
          ></vuetify-money>
          <v-row class="time-row">
            <v-col cols="12">
              <span id="newDate">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  append-icon="calendar_today"
                  v-model="receiveDetail.newDate"
                  label=" تاریخ "
                  :editable="true"
                  class="date-input"
                >
                </v-text-field>
              </span>

              <date-picker
                v-model="receiveDetail.newDate"
                element="newDate"
                color="#00a7b7"
              /> </v-col
            ><v-col cols="12">
              <span id="newTime">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  append-icon="mdi-clock-outline"
                  v-model="receiveDetail.newTime"
                  label=" ساعت"
                  :editable="true"
                  class="date-input"
                >
                </v-text-field>
              </span>

              <date-picker
                v-model="receiveDetail.newTime"
                element="newTime"
                type="time"
                color="#00a7b7"
              />
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col>
              <v-select
                v-model="receiveDetail.transferType"
                :items="paymentTypes"
                item-text="text"
                item-value="value"
                label="نحوه دریافت"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>

          <v-row
            v-if="
              receiveDetail.transferType == 'card' ||
              receiveDetail.transferType == 'cheque'
            "
          >
            <v-col class="pb-0">
              <div v-if="receiveDetail.transferType == 'card'">
                <v-text-field
                  outlined
                  dense
                  type="number"
                  v-model="receiveDetail.cardNumberSender"
                  label="شماره کارت فرستنده "
                  @wheel="$event.target.blur()"
                >
                </v-text-field>
                <v-text-field
                  outlined
                  dense
                  type="number"
                  v-model="receiveDetail.cardNumberReciever"
                  label="شماره کارت گیرنده "
                  @wheel="$event.target.blur()"
                >
                </v-text-field>
              </div>
              <div v-if="receiveDetail.transferType == 'cheque'">
                <v-text-field
                  outlined
                  dense
                  v-model="receiveDetail.chequeBankName"
                  label="نام بانک "
                >
                </v-text-field>
                <vuetify-money
                  v-model="receiveDetail.chequeAmount"
                  label="مبلغ چک (ریال)"
                  :valueWhenIsEmpty="whenIsEmpty"
                  :options="vMoneyOptions"
                  type="number"
                  outlined
                  dense
                ></vuetify-money>
                <v-text-field
                  outlined
                  dense
                  v-model="receiveDetail.chequeNumber"
                  label="شماره چک"
                >
                </v-text-field>
                <v-text-field
                  outlined
                  dense
                  v-model="receiveDetail.chequeSayyad"
                  label="شناسه صیاد"
                >
                </v-text-field>
                <span id="newCheque">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="receiveDetail.chequeNewDate"
                    label=" تاریخ چک"
                    :editable="true"
                    class="date-input"
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="receiveDetail.chequeNewDate"
                  element="newCheque"
                  color="#00a7b7"
                />
                <v-row class="time-row mb-4">
                  <v-col>
                    <v-select
                      v-model="receiveDetail.chequeStatus"
                      :items="chequeStatuses"
                      item-text="text"
                      item-value="value"
                      label="وضعیت چک"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
                <v-textarea
                  outlined
                  dense
                  type="text"
                  v-model="receiveDetail.chequeDescription"
                  label=" توضیحات چک"
                >
                </v-textarea>
              </div>
            </v-col>
          </v-row>
          <div
            :class="
              receiveDetail.transferType == 'cash' ||
              receiveDetail.transferType == 'pos' ||
              !receiveDetail.transferType
                ? 'mt-5'
                : 'mt-2'
            "
          >
            <vuetify-money
              v-model="receiveDetail.transactionFee"
              label="کارمزد انتقال وجه (ریال)"
              :valueWhenIsEmpty="whenIsEmpty"
              :options="vMoneyOptions"
              type="number"
              outlined
              dense
              v-if="
                receiveDetail.transferType == 'card' ||
                receiveDetail.transferType == 'cheque'
              "
            ></vuetify-money>
            <p class="text-right mb-2">
              * شما میتوانید بیش از یک فایل انتخاب کنید.
            </p>
            <v-file-input
              @change="myUpload(receiveDetail.file)"
              v-model="receiveDetail.file"
              chips
              outlined
              dense
              label="بارگذاری فایل"
              prepend-inner-icon="upload_file"
              prepend-icon=""
              class="file-input"
            ></v-file-input>
            <v-progress-linear
              rounded
              v-if="myFile.showProgress"
              height="10"
              :value="myFile.uploadPercentage"
              class="mb-4 mt-0"
            >
            </v-progress-linear>
          </div>
          <v-row>
            <div
              v-if="myFile.isUploaded"
              color="green"
              class="my-2 px-3"
              style="font-size: 16px"
            >
              فایل با موفقیت بارگذاری شد.
            </div>
          </v-row>
          <div v-if="uploadedFiles.length" class="files-box d-flex flex-column">
            <div class="files-title">
              فایل‌های آپلود شده ({{ uploadedFiles.length }})
            </div>
            <span
              v-for="(item, index) in uploadedFiles"
              :key="index"
              class="mb-2 pt-2"
            >
              <span @click="deleteFile(index)" class="pointer">
                <v-icon class="red--text text--lighten-1 pe-2"
                  >mdi-close-circle-outline</v-icon
                >
              </span>
              <a :href="item.url" target="blank">{{ showName[index] }}</a>
            </span>
          </div>
          <v-textarea
            v-model="receiveDetail.description"
            label="توضیحات"
            outlined
            dense
            class="mt-5"
          ></v-textarea>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn
            class="primary-btn"
            outlined
            :disabled="computedAddBtn || computedIsEmployee"
            @click="addToReceiveList()"
          >
            ثبت
          </v-btn>
          <v-btn
            class="red-btn"
            outlined
            @click="
              addReceiveItemDialog = false;
              uploadedFiles = [];
              myFile.isUploaded = false;
              myFile.showProgress = false;
            "
          >
            لغو
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment-jalaali";
import { mapActions } from "vuex";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import VueCkeditor from "vue-ckeditor2";
const MobileText = () => import("@/components/MobileText");
export default {
  components: {
    datePicker: VuePersianDatetimePicker,
    VueCkeditor,
    MobileText,
  },
  data() {
    return {
      Fields: [
        { key: "index", label: "ردیف" },
        { key: "patientName", label: "نام و نام خانوادگی" },
        { key: "visitNo", label: "  شماره نوبت" },
        { key: "visitType", label: "عنوان درخواست" },
        { key: "operation", label: " عملیات" },
      ],
      //CurrentPage: 1,
      //PerPage: 7,
      //perPageOptions: [
      //  { value: 10, text: "10" },
      //  { value: 25, text: "25" },
      //  { value: 50, text: "50" },
      //  { value: 100, text: "100" }
      //],
      receiveType: "employee",
      role: "",
      busy: false,
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      time: "",
      shiftStart: "00:00",
      shiftEnd: "23:59",
      persianMonths: [
        "فروردین",
        "اردیبهشت",
        "خرداد",
        "تیر",
        "مرداد",
        "شهریور",
        "مهر",
        "آبان",
        "آذر",
        "دی",
        "بهمن",
        "اسفند",
      ],
      dashboardInfo: {},
      shiftDoc: "",
      shiftNurse: "",
      changing: false,
      shiftDocOnCall: "",
      shiftNurseOnCall: "",
      dentistId: "",
      nurseDentistId: "",
      servant: "",
      shift: "",
      shifts: [
        { value: "صبح", text: "صبح" },
        { value: "عصر", text: "عصر" },
        { value: "شب", text: "شب" },
      ],
      useName: "",
      startShiftDialog: false,
      endShiftDialog: false,
      errorDialog: false,
      report: {
        shift: "",
        date: moment(new Date()).format("jYYYY/jMM/jDD"),
        shiftStart: "00:00",
        shiftEnd: "23:59",
        lastShiftNurse: [],
        lastShiftDoc: [],
        lastShiftServant: [],
        reception: "",
        pos: "",
        cash: "",
        lack: "",
        extra: "",
        talab: "",
        debt: "",
        cashDescription: "",
        isFacilitiesShortage: "",
        facilitiesShortage: "",
        isDissatisfaction: "",
        dissatisfaction: "",
        others: "",
        suggestions: "",
        ePrepHealth: "",
        ePrepMilitary: "",
        ePrepSocial: "",
        isReferral: "no",
        referral: {
          lab: { no: 0, names: "" },
          physio: { no: 0, names: "" },
          radio: { no: 0, names: "" },
          dentistery: { no: 0, names: "" },
          women: { no: 0, names: "" },
          babies: { no: 0, names: "" },
          internal: { no: 0, names: "" },
          heart: { no: 0, names: "" },
          ortho: { no: 0, names: "" },
          ent: { no: 0, names: "" },
          surgery: { no: 0, names: "" },
          orology: { no: 0, names: "" },
          infectious: { no: 0, names: "" },
          skin: { no: 0, names: "" },
        },
      },
      addReceiveItemDialog: false,
      receiveDetail: {
        userId: "",
        cardNumberSender: "",
        cardNumberReciever: "",
        chequeBankName: "",
        chequeAmount: null,
        chequeNumber: "",
        chequeNewDate: "",
        chequeDescription: "",
        chequeSayyad: "",
        chequeStatus: "",
      },
      chequeStatuses: [
        { text: "پاس شده", value: "passed" },
        { text: "برگشت خورده", value: "returned" },
        { text: "ثبت شده", value: "submitted" },
      ],
      myFile: new Object({
        uploadPercentage: 0,
      }),
      fileIds: [],
      uploadedFiles: [],
      employees: [],
      employeeBusy: false,
      showName: [],
      receiverEmployee: false,
      reactivity: false,
      config: {
        toolbar: [
          [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "JustifyLeft",
            "JustifyCenter",
            "JustifyRight",
            "Font",
          ],
        ],
      },
    };
  },
  methods: {
    ...mapActions(["getCurrent"]),
    goToChangeShiftDetails() {
      this.$router.push("/reception/shiftDetails");
    },
    goToNewRequest() {
      this.$router.push("NewRequest");
    },
    goToNewHomeCare() {
      this.$router.push("NewHomeCare");
    },
    splitDate(Date) {
      var dateparts = Date.split("-");
      var year = dateparts[0];
      var day = dateparts[2];
      var month = this.persianMonths[parseInt(dateparts[1] - 1)];
      return {
        day: day,
        month: month,
        year: year,
      };
    },
    getTime() {
      var now = new Date().toLocaleTimeString("fa-IR");
      now.split(":");
      now = now.substring(0, now.length - 3);
      this.time = now;
      setTimeout(() => {
        this.getTime();
      }, 60000);
    },
    getDashboardInfo() {
      this.vLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/dashboard/receptionDashboard",
          {},
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.vLoading = false;

          if (res.status == 200) {
            if (res.data.shift.receptionName) {
              this.startShiftDialog = false;
              this.dashboardInfo = res.data;
            } else {
              this.startShiftDialog = true;
              this.dashboardInfo = {};
              this.shiftDoc = "";
              this.shiftNurse = "";
              this.dentistId = "";
              this.nurseDentistId = "";
              this.shiftDocOnCall = "";
              this.shiftNurseOnCall = "";
              this.servant = "";
            }
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.vLoading = false;
          console.log(err);
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    startShift() {
      this.changing = true;
      this.busy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/shift/start",
          {
            docId: this.shiftDoc,
            nurseId: this.shiftNurse,
            subject: this.shift,
            oncallDocId: this.shiftDocOnCall,
            oncallNurseId: this.shiftNurseOnCall,
            servantId: this.servant,
            dentistId: this.dentistId,
            nurseDentistId: this.nurseDentistId,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.busy = false;
          this.changing = false;
          this.getCurrent();
          if (res.status == 201) {
            this.toast("شیفت با موفقیت آغاز گردید.", "success");
            this.startShiftDialog = false;
            this.getDashboardInfo();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          console.log(err);
          this.toast("خطا: " + err.response.data, "error");
          this.busy = false;
          this.changing = false;
        });
    },
    showEendShift() {
      if (this.shiftDetails.receptionName == this.userName) {
        this.report = {
          shift: "",
          date: moment(new Date()).format("jYYYY/jMM/jDD"),
          shiftStart: "00:00",
          shiftEnd: "23:59",
          lastShiftNurse: [],
          lastShiftDoc: [],
          lastShiftServant: [],
          reception: "",
          pos: "",
          cash: "",
          lack: "",
          extra: "",
          talab: "",
          debt: "",
          cashDescription: "",
          isFacilitiesShortage: "",
          facilitiesShortage: "",
          isDissatisfaction: "",
          dissatisfaction: "",
          others: "",
          suggestions: "",
          ePrepHealth: "",
          ePrepMilitary: "",
          ePrepSocial: "",
          isReferral: "no",
          referral: {
            lab: { no: 0, names: "" },
            physio: { no: 0, names: "" },
            radio: { no: 0, names: "" },
            dentistery: { no: 0, names: "" },
            women: { no: 0, names: "" },
            babies: { no: 0, names: "" },
            internal: { no: 0, names: "" },
            heart: { no: 0, names: "" },
            ortho: { no: 0, names: "" },
            ent: { no: 0, names: "" },
            surgery: { no: 0, names: "" },
            orology: { no: 0, names: "" },
            infectious: { no: 0, names: "" },
            skin: { no: 0, names: "" },
          },
        };
        this.endShiftDialog = true;
      } else {
        this.errorDialog = true;
      }
    },
    endShift() {
      this.changing = true;
      this.busy = true;
      this.report.reception = this.userName;
      this.report.cash = this.report.cash ? this.report.cash : "0";
      this.report.pos = this.report.pos ? this.report.pos : "0";
      this.$http
        .post(
          this.baseUrl + "/clinic/shift/end",
          {
            shiftId: this.shiftDetails.id,
            report: JSON.stringify(this.report),
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.busy = false;
          this.changing = false;
          if (res.status == 200) {
            this.toast("شیفت با موفقیت پایان یافت.", "success");
            this.getCurrent();
            this.endShiftDialog = false;
            this.getDashboardInfo();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          console.log(err);
          this.toast("خطا: " + err.response.data, "error");
          this.busy = false;
          this.changing = false;
        });
    },
    openAddDialog() {
      this.addReceiveItemDialog = true;
      this.receiveDetail = {
        cardNumberSender: "",
        cardNumberReciever: "",
        chequeBankName: "",
        chequeAmount: "",
        chequeNumber: "",
        chequeNewDate: "",
        chequeSayyad: "",
        newDate: moment(new Date()).format("jYYYY/jMM/jDD"),
        newTime: moment(new Date()).format("HH:mm"),
      };
      this.uploadedFiles = [];
      this.myFile.isUploaded = false;
      this.myFile.showProgress = false;
      this.getEmployeesList();
    },
    getEmployeesList() {
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/employees/list",
          {},
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.employeeBusy = false;
          if (res.status == 200) {
            this.receiveDetail.userId = "";
            this.employees = res.data.map((x) => {
              return {
                id: x.id,
                text: x.name,
              };
            });
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.Busy = false;
        });
    },
    addToReceiveList() {
      this.vLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/financial/document/newReceiveFree",
          {
            date: this.receiveDetail.newDate + this.receiveDetail.newTime,
            title: this.receiveDetail.title,
            amount: this.receiveDetail.amount,
            description: this.receiveDetail.description,
            transactionFee:
              this.receiveDetail.transferType == "card" ||
              this.receiveDetail.transferType == "cheque"
                ? this.receiveDetail.transactionFee
                : "",
            transferType: this.receiveDetail.transferType,
            chequeAmount:
              this.receiveDetail.transferType == "cheque"
                ? this.receiveDetail.chequeAmount
                : "",
            chequeNumber:
              this.receiveDetail.transferType == "cheque"
                ? this.receiveDetail.chequeNumber
                : "",
            chequeBankName:
              this.receiveDetail.transferType == "cheque"
                ? this.receiveDetail.chequeBankName
                : "",
            chequeDueDate:
              this.receiveDetail.transferType == "cheque"
                ? this.receiveDetail.chequeNewDate
                : "",
            chequeSayyad:
              this.receiveDetail.transferType == "cheque"
                ? this.receiveDetail.chequeSayyad
                : "",
            chequeStatus:
              this.receiveDetail.transferType == "cheque"
                ? this.receiveDetail.chequeStatus
                : "",
            chequeDescription:
              this.receiveDetail.transferType == "cheque"
                ? this.receiveDetail.chequeDescription
                : "",
            cardNumberSender:
              this.receiveDetail.transferType == "card"
                ? this.receiveDetail.cardNumberSender
                : "",
            cardNumberReciever:
              this.receiveDetail.transferType == "card"
                ? this.receiveDetail.cardNumberReciever
                : "",
            userId:
              this.receiveType == "employee" ? this.receiveDetail.userId : "",
            fileIds: this.fileIds,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 201) {
            this.vLoading = false;
            this.toast("اطلاعات دریافتی  جدید با موفقیت ثبت شد", "success");
            this.addReceiveItemDialog = false;
            this.receiveDetail = {
              cardNumberSender: "",
              cardNumberReciever: "",
              chequeBankName: "",
              chequeAmount: "",
              chequeNumber: "",
              chequeNewDate: "",
              chequeSayyad: "",
              chequeStatus: "",
            };
            this.receiverEmployee = false;
            this.showName = [];
            this.uploadedFiles = [];
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.vLoading = false;
            this.addReceiveItemDialog = false;
            this.receiveDetail = {
              cardNumberSender: "",
              cardNumberReciever: "",
              chequeBankName: "",
              chequeAmount: "",
              chequeNumber: "",
              chequeNewDate: "",
              chequeSayyad: "",
              chequeStatus: "",
            };
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
          this.addReceiveItemDialog = false;
          this.receiveDetail = {
            cardNumberSender: "",
            cardNumberReciever: "",
            chequeBankName: "",
            chequeAmount: "",
            chequeNumber: "",
            chequeNewDate: "",
            chequeSayyad: "",
            chequeStatus: "",
          };
        });
    },
    myUpload(file) {
      // NOTE (m-myUpload) to upload files
      this.myFile.showProgress = true;
      this.myFile.variant = "info";
      this.myFile.uploadPercentage = 0;
      this.myFile.isUploaded = false;
      this.myFile.uploadButDisable = true;
      var formData = new FormData();
      this.showName.push(file.name);
      formData.append(file.name, file);

      this.$http
        .post(this.baseUrl + "/clinic/financial/document/upload", formData, {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
          onUploadProgress: ((progressEvent) => {
            this.myFile.uploadPercentage =
              Math.round((progressEvent.loaded * 100) / progressEvent.total) -
              1;
          }).bind(this),
        })
        .then((d) => {
          if (d.status == 201) {
            this.myFile.isUploaded = true;
            this.myFile.uploadPercentage = 100;
            this.myFile.striped = false;
            this.myFile.variant = "success";
            this.myFile.uploadButDisable = false;
            this.uploadedFiles.push(d.data);
            this.fileIds.push(d.data.id);
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    deleteFile(index) {
      this.uploadedFiles.splice(index, 1);
      this.fileIds.splice(index, 1);
      this.showName.splice(index, 1);
    },
  },
  watch: {
    shiftDetails(val) {
      if (!val.status) {
        this.startShiftDialog = true;
        this.shiftDoc = "";
        this.shiftNurse = "";
        this.dentistId = "";
        this.nurseDentistId = "";
        this.shiftDocOnCall = "";
        this.shiftNurseOnCall = "";
        this.servant = "";
      } else {
        this.startShiftDialog = false;
      }
    },
  },
  computed: {
    ...mapGetters([
      "drNames",
      "nurseNames",
      "servantNames",
      "shiftDetails",
      "userName",
      "paymentTypes",
    ]),
    computedAddBtn() {
      if (
        this.receiveDetail.amount &&
        this.receiveDetail.newDate &&
        this.receiveDetail.newTime &&
        this.receiveDetail.title &&
        this.receiveDetail.transferType &&
        this.receiveDetail.description
      ) {
        if (this.receiveDetail.transferType == "card") {
          if (
            this.receiveDetail.cardNumberSender &&
            this.receiveDetail.cardNumberReciever &&
            this.receiveDetail.transactionFee
          ) {
            return false;
          } else return true;
        } else if (this.receiveDetail.transferType == "cheque") {
          if (
            this.receiveDetail.chequeBankName &&
            this.receiveDetail.chequeAmount &&
            this.receiveDetail.chequeNumber &&
            this.receiveDetail.chequeNewDate &&
            this.receiveDetail.chequeStatus &&
            this.receiveDetail.transactionFee
          ) {
            return false;
          } else return true;
        } else return false;
      } else return true;
    },
    computedIsEmployee() {
      if (this.receiveType == "employee") {
        if (this.receiveDetail.userId) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.role = localStorage.getItem("role");

    this.getDashboardInfo();
    this.getTime();
  },
  created() {
    this.getCurrent();
  },
};
</script>
<style lang="scss">
.files-box {
  border: 1px solid rgb(172, 172, 172);
  text-align: right;
  font-size: 14px;
  margin-top: 35px;
  padding: 10px;
  border-radius: 7px;
  position: relative;
  ul {
    li {
      text-decoration: none;
    }
  }
  .files-title {
    position: absolute;
    top: -9px;
    right: 20px;
    background-color: #fff;
    font-size: 14px;
    padding: 0 6px;
  }
}
.v-file-input .v-file-input__text.v-file-input__text--chips {
  display: none !important;
}

.v-input__icon--clear {
  display: none !important;
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 80% !important;
}
</style>
